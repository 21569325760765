<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="dialogChange" max-width="600px">
      <v-card>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-card tile flat>
              <v-layout
                wrap
                justify-center
                px-8
                py-5
                style="background-color: #b6b6b626"
              >
                <v-flex xs11 text-left>
                  <span
                    style="
                      font-family: poppinsbold;
                      font-size: 20px;
                      color: #000000;
                    "
                  >
                    Replace cart item?
                  </span>
                </v-flex>
                <v-flex xs1>
                  <v-icon @click="dialogChange = false" color="#000000"
                    >mdi-close</v-icon
                  >
                </v-flex>
              </v-layout>
              <v-layout
                wrap
                justify-center
                py-8
                px-5
                style="
                  font-size: 16px;
                  color: #1f1f1f;
                  font-family: poppinssemibold;
                "
              >
                <v-flex xs12 sm12 text-left px-2>
                  <span
                    >Your cart contains products from another seller. Do you
                    want to move those items to Wishlist and replace cart with
                    this product
                  </span>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-end py-5>
                <v-flex xs6 px-2 text-right>
                  <v-btn
                    :ripple="false"
                    depressed
                    block
                    tile
                    color="#9D9896"
                    @click="dialogChange = false"
                  >
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 12px;
                        color: #fff;
                        text-transform: none;
                      "
                    >
                      No
                    </span>
                  </v-btn>
                </v-flex>
                <v-flex xs6 px-2 text-right>
                  <v-btn
                    :ripple="false"
                    depressed
                    block
                    tile
                    color="#30B868"
                    @click="addCart()"
                  >
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 12px;
                        color: #fff;
                        text-transform: none;
                      "
                    >
                      Yes
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center fill-height py-16>
      <v-flex xs12 sm10>
        <v-layout wrap>
          <v-flex xs12 sm12 md5 lg5 xl4>
            <v-layout wrap>
              <v-flex xs12 sm3 md3 class="hidden-xs-only">
                <v-layout
                  wrap
                  pa-2
                  v-for="(item, i) in productData.photos"
                  :key="i"
                >
                  <v-card
                    outlined
                    tile
                    color="#F5F5F5"
                    @click="showImage(item)"
                  >
                    <v-layout wrap justify-center fill-height>
                      <v-flex xs12 sm6 md6 align-self-center py-4>
                        <v-img
                          :src="mediaUURL + item"
                          height="80px"
                          contain
                          alt="Image"
                        >
                          <template v-slot:placeholder>
                            <ImageLoader />
                          </template>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm9 md9 pa-2>
                <v-card outlined tile height="100%" color="#F5F5F5">
                  <v-layout
                    wrap
                    justify-center
                    fill-height
                    py-10
                    v-if="productData.photos"
                  >
                    <v-flex xs12 sm6 md8 align-self-center v-if="!curImage">
                      <!-- <v-img
                        height="100%"
                        contain
                        src="./../../assets/images/image38.png"
                      ></v-img> -->
                      <inner-image-zoom
                        zoomType="hover"
                        :hideHint="true"
                        :zoomPreload="true"
                        :src="mediaUURL + productData.photos[0]"
                        :zoomSrc="mediaUURL + productData.photos[0]"
                      >
                      </inner-image-zoom>
                    </v-flex>
                    <v-flex xs12 sm6 md8 align-self-center v-if="curImage">
                      <!-- <v-img
                        height="100%"
                        contain
                        src="./../../assets/images/image38.png"
                      ></v-img> -->
                      <inner-image-zoom
                        zoomType="hover"
                        :hideHint="true"
                        :zoomPreload="true"
                        :src="mediaUURL + curImage"
                        :zoomSrc="mediaUURL + curImage"
                      >
                      </inner-image-zoom>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                xs4
                sm3
                md3
                v-for="(item, i) in productData.photos"
                :key="i"
                class="hidden-sm-and-up"
              >
                <v-layout wrap pa-2>
                  <v-card
                    outlined
                    tile
                    color="#F5F5F5"
                    @click="showImage(item)"
                  >
                    <v-layout wrap justify-center fill-height>
                      <v-flex xs12 sm6 md6 align-self-center py-4>
                        <v-img
                          :src="mediaUURL + item"
                          height="80px"
                          contain
                          alt="Image"
                        >
                          <template v-slot:placeholder>
                            <ImageLoader />
                          </template>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md7 lg7 xl8 pl-xs0 pl-sm-0 pl-md-5 pl-lg-5 pl-xl-5>
            <v-layout wrap justify-center>
              <v-flex xs11>
                <v-layout wrap justify-start>
                  <v-flex xs12 text-left>
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 22px;
                        color: #000000;
                        text-transform: uppercase;
                      "
                    >
                      {{ product.productname }}
                    </span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <v-layout wrap>
                      <v-flex xs12 sm6 md2 lg2>
                        <v-rating
                          color="#F28F34"
                          background-color="#F28F34"
                          half-increments
                          readonly
                          small
                          hover
                          v-model="product.averageRating"
                        ></v-rating>
                      </v-flex>
                      <v-flex xs12 sm6 md2 text-left>
                        <span
                          style="
                            color: #2b2b2b;
                            font-family: poppinsregular;
                            font-size: 12px;
                          "
                          >({{ product.noOfFeedback }} reviews)</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 text-left pt-2 v-if="product.instock == true">
                    <v-btn elevation="0" color="#D7FFE98F" rounded>
                      <v-icon small color="#30B868"
                        >mdi-check-circle-outline</v-icon
                      >
                      <span
                        style="
                          color: #30b868;
                          text-transform: none;
                          font-family: poppinsregular;
                        "
                        >&nbsp;In Stock</span
                      ></v-btn
                    >
                  </v-flex>
                  <v-flex xs12 text-left pt-2 v-else>
                    <v-btn elevation="0" color="#D7FFE98F" rounded>
                      <v-icon small color="red"
                        >mdi-close-circle-outline</v-icon
                      >
                      <span
                        style="
                          color: red;
                          text-transform: none;
                          font-family: poppinsregular;
                        "
                        >&nbsp;Out of Stock</span
                      ></v-btn
                    >
                  </v-flex>
                  <v-flex xs12 text-left pt-5>
                    <span
                      style="
                        color: #333333;
                        font-family: opensansbold;
                        font-size: 20px;
                      "
                    >
                      ₹{{ product.offerPrice }} &nbsp;</span
                    >
                    <span
                      v-if="product.offerPrice != product.price"
                      style="
                        color: #8d8d8d;
                        font-size: 12px;
                        font-family: opensansregular;
                      "
                      class="pl-1 text-decoration-line-through"
                      >₹{{ product.price }}</span
                    >
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span
                      style="
                        color: #30b868;
                        font-size: 12px;
                        font-family: poppinsmedium;
                      "
                      >Inclusive of all taxes</span
                    >
                  </v-flex>
                  <v-flex xs12 sm8 text-left>
                    <span
                      style="
                        color: #2b2b2b;
                        font-size: 13px;
                        font-family: poppinsregular;
                      "
                      >{{ productData.description }}.</span
                    >
                  </v-flex>
                  <v-flex xs12 sm12 text-left pt-5 v-if="product.size">
                    <span
                      style="
                        color: #373737;
                        font-family: poppinsregular;
                        font-size: 15px;
                      "
                      >Select size &nbsp; &nbsp;</span
                    >
                    <span
                      style="
                        color: #ff7c03;
                        font-family: poppinsmedium;
                        text-decoration: underline;
                        font-size: 15px;
                      "
                      >SIZE CHART</span
                    >
                  </v-flex>
                  <v-flex xs12 text-left pt-2 v-if="product.size">
                    <span
                      class="pr-2"
                      v-for="(item, i) in product.items"
                      :key="i"
                      style="padding-top: 10px !important"
                    >
                      <span v-if="item.size">
                        <v-btn
                          small
                          light
                          depressed
                          outlined
                          @click="changeSize(item)"
                          :ripple="false"
                          :color="
                            productSize == item.size ? '#FF0000' : '#3A3A3A'
                          "
                          class="sizebutton px-2"
                        >
                          <span style="font-family: poppinssemibold">{{
                            item.size
                          }}</span>
                        </v-btn>
                      </span>
                    </span>
                  </v-flex>
                  <v-flex xs12 sm12 text-left pt-5>
                    <span
                      v-for="(list, l) in product.items"
                      :key="l"
                      style="
                        color: #373737;
                        font-family: poppinsregular;
                        font-size: 15px;
                      "
                    >
                      <span v-if="list.stock[0].color!='null' && list.stock[0].color!=null">Colour</span>
                    </span>
                  </v-flex>
                  <v-layout wrap v-for="(data, d) in product.items" :key="d">
                    <v-flex
                      text-left
                      lg12
                      v-for="(co, c) in data.stock"
                      :key="c"
                    >
                      <v-card
                        v-if="co.color!=null && co.color!='null'"
                        outlined
                        rounded="30px"
                        class="colorselector"
                        width="30px"
                        :color="co.color ? '#ffebeb' : ''"
                      >
                        <v-layout py-2 wrap align-content-center>
                          <v-flex xs12 text-center>
                            <v-avatar :color="co.color" size="15" />
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <v-flex xs12 sm12 text-left pt-5>
                    <span
                      style="
                        color: #373737;
                        font-family: poppinsregular;
                        font-size: 15px;
                      "
                      >Quantity
                    </span>
                  </v-flex>
                  <v-flex xs12 sm12 text-left pt-5>
                    <v-layout wrap>
                      <v-flex xs3 sm2 md2 lg2 xl1>
                        <v-card outlined>
                          <v-select
                            dense
                            v-model="quantity"
                            :items="list"
                            item-text="name"
                            item-value="value"
                            height="29px"
                            color="grey"
                            class="no-border pl-4"
                            variant="underlined"
                            :hide-details="true"
                          >
                          </v-select>
                        </v-card>
                      </v-flex>
                      <v-flex xs9 sm6 md4 px-5>
                        <v-btn
                          color="#E92560"
                          block
                          depressed
                          @click="addWishlist()"
                          ><v-icon
                            v-if="product.inWishList == false"
                            color="white"
                            small
                            >mdi-heart-outline</v-icon
                          >
                          <v-icon v-else color="white" small
                            >mdi-check-circle-outline</v-icon
                          >
                          <span
                            v-if="product.inWishList == false"
                            style="
                              color: #ffffff;
                              font-family: poppinsregular;
                              text-transform: none;
                            "
                          >
                            &nbsp; Wishlist</span
                          >
                          <span
                            v-else
                            style="
                              color: #ffffff;
                              font-family: poppinsregular;
                              text-transform: none;
                            "
                          >
                            &nbsp;In Wishlist</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pr-5 pt-3 text-left>
                    <v-btn
                      color="#E96125"
                      block
                      depressed
                      @click="checkSeller()"
                      ><span
                        v-if="product.inCart == false"
                        style="
                          color: #ffffff;
                          font-family: poppinsregular;
                          text-transform: none;
                        "
                      >
                        Buy now</span
                      >
                      <span
                        v-else
                        style="
                          color: #ffffff;
                          font-family: poppinsregular;
                          text-transform: none;
                        "
                      >
                        In Cart</span
                      ></v-btn
                    >
                  </v-flex>
                  <v-flex xs12 sm12 md12 py-5>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-card color="#F5F5F5" flat>
                      <v-layout wrap py-5>
                        <v-flex xs3 sm3>
                          <v-img
                            contain
                            height="25px"
                            src="./../../assets/icons/img1.png"
                          ></v-img>
                          <span
                            style="
                              font-family: poppinsmedium;
                              font-size: 12px;
                              line-height: 0px;
                            "
                            >Fast <br />
                            Shipping</span
                          >
                        </v-flex>
                        <v-flex xs3 sm3>
                          <v-img
                            contain
                            height="25px"
                            src="./../../assets/icons/img2.png"
                          ></v-img>
                          <span
                            style="font-family: poppinsmedium; font-size: 12px"
                            >Secure <br />
                            Checkout</span
                          >
                        </v-flex>
                        <v-flex xs3 sm3>
                          <v-img
                            contain
                            height="25px"
                            src="./../../assets/icons/img3.png"
                          ></v-img>
                          <span
                            style="font-family: poppinsmedium; font-size: 12px"
                            >Quality <br />
                            Products</span
                          >
                        </v-flex>
                        <v-flex xs3 sm3>
                          <v-img
                            contain
                            height="25px"
                            src="./../../assets/icons/img4.png"
                          ></v-img>
                          <span
                            style="font-family: poppinsmedium; font-size: 12px"
                            >Eco- <br />
                            friendly</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <ProductReview :reviews="reviews" />
      </v-flex>
      <v-flex xs12 v-if="relatedProducts.length > 0">
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 sm10 v-if="relatedProducts.length > 0">
        <SimilarProducts :relatedProducts="relatedProducts" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
// import ImageZoomer from "./imageZoomer";
import ProductReview from "./productReview";
import SimilarProducts from "./similarProducts";
export default {
  // props: ["productData", "productPhotos"],
  components: {
    // ImageZoomer,
    "inner-image-zoom": InnerImageZoom,
    ProductReview,
    SimilarProducts,
  },
  //  metaInfo: {
  //   title: 'Hello World!'
  // },
  data() {
    return {
      appLoading: false,
      wishlistLoading: false,
      cartLoading: false,
      showSnackBar: false,
      timeout: 5000,
      ServerError: false,
      msg: null,
      shopDialog: false,
      Rules: [(v) => !!v || "Required"],
      product: {},
      productData: {},
      productArray: [],
      curProduct: null,
      curProductEdit: null,
      productSize: {},
      selectedProduct: {},
      curImage: null,
      sizeChart: null,
      sizeChartDialog: false,
      shareDialog: false,
      dialogChange: false,
      pincode: "",
      pincodeMsg: "",
      rating: 0,
      quantity: 1,
      wishlist: [],
      list: [],
      reviews: [],
      relatedProducts: [],
      items: ["500 gm", "250 gm"],
      itemId: "",
    };
  },
  computed: {
    appLogin() {
      if (this.$store.state.isLoggedIn) return this.$store.state.isLoggedIn;
      else return null;
    },
  },
  beforeMount() {
    this.getProducts();
  },
  methods: {
    changeSize(item) {
      this.productSize = item.size;
      for (var i = 1; i <= item.stock[0].stock; i++) {
        this.list.push(i);
      }
      this.product.offerPrice = item.offerPrice;
      this.product.price = item.price;
      this.itemId = item._id;
      this.product.instock = item.instock;
      this.product.size = item.size;
      this.product.inWishList = item.inWishList;
      this.product.inCart = item.inCart;
    },
    showImage(item) {
      this.curImage = item;
    },
    getProducts() {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/product/view",
        method: "POST",
        data: {
          id: this.$route.query.id,
          productName: this.$route.query.productName,
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          this.product = response.data.data;
          this.reviews = response.data.review;
          this.productData = this.product;
          this.relatedProducts = response.data.relatedProducts;
          if (this.product) {
            if (this.product.items.length > 0) {
              this.changeSize(this.product.items[0]);
              this.productSize = this.product.items[0].size;
              this.product.instock = this.product.items[0].instock;
              this.product.size = this.product.items[0].size;
              this.product.inWishList = this.product.items[0].inWishList;
              this.product.inCart = this.product.items[0].inCart;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    checkSeller() {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/cart/validate",
        method: "POST",
        data: {
          itemId: this.itemId,
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.cart = response.data.data;
            // this.$store.commit("changeCart", response.data.totalLength);
            // this.$router.push("/cart");
            if (response.data.otherSellerProductsExist) {
              this.dialogChange = true;
            } else {
              this.addCart();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addCart() {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/cart/add",
        method: "POST",
        data: {
          itemId: this.itemId,
          // guestid:this.guestid
          quantity: this.quantity,
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cart = response.data.data;
            this.$store.commit("changeCart", response.data.totalLength);
            this.$router.push("/cart");
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addWishlist() {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/wishList/add",
        method: "POST",
        data: {
          id: this.itemId,
          // guestid:this.guestid
          // quantity:this.quantity
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.wishlist = response.data.data;
            this.$store.commit("changeWishlist", response.data.wishlistcount);
            this.$router.push("/wishlist");
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.theme--light.v-text-field.no-border
  > .v-input__control
  > .v-input__slot::before {
  border-width: 0;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */
.top-left {
  position: absolute;
  top: 12px;
  left: 11px;
}

.top-left-xs {
  position: absolute;
  top: 12px;
  left: 11px;
}

.bottom-right-xs {
  position: absolute;
  bottom: -8vh;
  right: 20px;
}

.bottom-right-sm {
  position: absolute;
  bottom: -7vh;
  right: 12px;
}

.bottom-right-md {
  position: absolute;
  bottom: -7vh;
  right: 10px;
}

.bottom-right {
  position: absolute;
  bottom: -8vh;
  right: 10px;
}

.colorselector.v-sheet.v-card {
  border-radius: 35px;
}

.sizebutton.v-btn:not(.v-btn--round).v-size--small {
  padding: 0 12px !important;
  min-width: 30px !important;
}

.rectangle2 {
  height: 20px;
  width: 100%;
  padding: 2%;
  opacity: 0.8;
}

.triangle-rights3 {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.8;
}

.triangle-rights2 {
  margin-left: -38px;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-bottom: 20px solid transparent;
  opacity: 0.8;
}

.triangle-rights1 {
  margin-left: -80px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 0px solid transparent;
  opacity: 0.8;
}

@media only screen and (max-width: 374px) {
  .rectangle2 {
    height: 20px;
    width: 100%;
    padding: 0%;
    opacity: 0.8;
  }

  .triangle-rights3 {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.8;
  }

  .triangle-rights2 {
    margin-left: -33px;
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 20px solid transparent;
    opacity: 0.8;
  }

  .triangle-rights1 {
    margin-left: -56px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 0px solid transparent;
    opacity: 0.8;
  }

  @media only screen and (min-width: 373px) and (max-width: 424px) {
    .rectangle2 {
      height: 20px;
      width: 100%;
      padding: 0%;
      opacity: 0.8;
    }

    .triangle-rights3 {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      opacity: 0.8;
    }

    .triangle-rights2 {
      margin-left: -50px;
      width: 0;
      height: 0;
      border-top: 0px solid transparent;
      border-bottom: 20px solid transparent;
      opacity: 0.8;
    }

    .triangle-rights1 {
      margin-left: -86px;
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 0px solid transparent;
      opacity: 0.8;
    }
  }
}

@media only screen and (min-width: 748px) and (max-width: 1023px) {
  .top-left {
    position: absolute;
    top: 18px;
    left: 40px;
  }

  .rectangle2 {
    height: 20px;
    width: 100%;
    padding: 0%;
    opacity: 0.8;
  }

  .triangle-rights3 {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.8;
  }

  .triangle-rights2 {
    margin-left: -100px;
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 20px solid transparent;
    opacity: 0.8;
  }

  .triangle-rights1 {
    margin-left: -170px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 0px solid transparent;
    opacity: 0.8;
  }
}
</style>