<template>
  <div>
    <v-layout wrap justify-center py-10>
      <v-flex
        xs11
        sm12
        pr-xs-0
        pr-sm-0
        pr-md-8
        pr-lg-8
        pr-xl-8
        v-if="reviews.length > 0"
      >
        <v-card outlined>
          <v-layout wrap py-5 px-xs-0 px-sm-0 px-md-16 px-lg-16 px-xl-16>
            <v-flex xs12 text-left>
              <span
                style="
                  color: #000000;
                  font-size: 25px;
                  font-family: poppinsmedium;
                "
                >Reviews
              </span>
            </v-flex>
            <v-flex xs12 pt-5 v-for="(item, i) in reviews" :key="i">
              <v-layout wrap justify-start>
                <v-flex xs2 sm1 text-left>
                  <v-img
                    height="80px"
                    contain
                    src="./../../assets/icons/reviewuser.png"
                  ></v-img>
                </v-flex>
                <v-flex xs10 sm11 pl-5 pt-2 text-left>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-rating
                        color="#F28F34"
                        background-color="#F28F34"
                        half-increments
                        hover
                        v-model="item.rating"
                        small
                      ></v-rating>
                    </v-flex>
                    <v-flex xs12>
                      <span
                        style="
                          font-family: poppinsregular;
                          color: #000000;
                          font-size: 14px; 
                        "
                        >{{ item.userId.name }}</span
                      ><span
                        style="
                          color: #727272;
                          font-family: poppinsregular;
                          font-size: 12px;
                        "
                      >
                        - {{ formatDate(item.create_date) }}</span
                      >
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <span
                        style="
                          color: #3c3c3c;
                          font-family: RobotoRegular;
                          font-size: 13px;
                        "
                        >{{ item.review }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-flex xs12 pt-8 v-if="i < reviews.length - 1">
                <v-divider></v-divider>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["reviews"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      // day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>